import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { dateState, phoneState, verifyModalState } from '../../recoil/loginAtoms';
import axios from '../../utils/axios';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import loginLogo from '../../assests/images/LoginLogo.svg';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import firstLogo from '../../assests/images/FirstLogo.svg';
import secondLogo from '../../assests/images/SecondLogo.svg';
import DialogActions from '@mui/material/DialogActions';
import { authState } from '../../recoil/authAtom';
import { useNavigate } from 'react-router-dom';
import useLogin from '../../store/AuthStore';

export const LogReg = ({ open, handleClose }) => {
  const [phonePrefixes, setPhonePrefixes] = useState([]);
  const [phoneDataState, setPhoneDataState] = useRecoilState(phoneState);
  const [showPassword, setShowPassword] = useState(false);
  const [verify, setVerify] = useRecoilState(verifyModalState);
  const [password, setPassword] = useState('');

  const handleSubmit = () => {
    let log = {};
    if (phoneDataState.phoneNumber === '380963323627') {
      log = {
        hp_number: `+${phoneDataState.phoneNumber}`,
        password: password,
      };
    } else {
      log = {
        hp_number: phoneDataState.chosenPrefix + phoneDataState.phoneNumber,
        password: password,
      };
    }
    axios.post('auth/login', log).then(data => {
      if (data.status === 200) {
        if (data.data.access_token) {
          localStorage.setItem('token', data.data.access_token);
          useLogin.setState({ auth: true, expiresIn: data.data.expires_in, accessToken: data.data.access_token });
        } else {
          setVerify(prev => ({
            ...prev,
            shouldOpenModal: true,
            isLogin: true,
          }));
        }
      }
      handleClose();
    });
  };

  const handleForgotSubmit = () => {
    let data = {};
    if (phoneDataState.phoneNumber === '380963323627') {
      data = {
        hp_number: `+${phoneDataState.phoneNumber}`,
      };
    } else {
      data = {
        hp_number: phoneDataState.chosenPrefix + phoneDataState.phoneNumber,
      };
    }
    axios.post('auth/reset_password', data).then(data => {
      setVerify(prev => ({
        ...prev,
        shouldOpenModal: true,
        isLogin: true,
        forgotPassword: true,
      }));
    });
    handleClose();
  };

  useEffect(() => {
    axios.get('auth/login').then(data => {
      setPhonePrefixes(data.data.country_codes);

      /// REMOVE THIS SETTER IF YOU WANT HAVE EMPTY NUMBER
      setPhoneDataState(prev => ({
        ...prev,
        chosenPrefix: data.data.country_codes[0],
      }));
    });
  }, []);

  const handleForgotPassword = () => {
    setVerify(prev => ({
      ...prev,
      forgotPassword: true,
    }));
  };

  const handlePhoneNumberChange = e => {
    setPhoneDataState(prevState => ({
      ...prevState,
      phoneNumber: e.target.value,
    }));
  };

  const handlePasswordChange = ({ target: { value } }) => {
    setPassword(value);
  };

  const togglePasswordShow = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="">
      <Dialog
        className="dialog"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: '630px',
              height: '100%',
              maxHeight: '480px',
            },
          },
        }}
      >
        <DialogTitle id="alert-dialog-title" className="dialog-nav">
          <img className="login-icon" src={loginLogo} alt="loginLogo" />

          <span className="dialog-nav__title">Login/Register</span>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: theme => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        {verify.forgotPassword && <h2 className="h2-password-reset">Please enter the number to reset your password</h2>}
        <DialogContent>
          <div className="dialog-content">
            <label className="dialog-content__labels">HP Number</label>
            <div className="dialog-content__hp-number">
              <img className="dialog-content__number" src={firstLogo} alt="FirstLogo" />
              <input
                className="select select-phone"
                defaultValue={phonePrefixes[0]}
                variant="standard"
                sx={{ m: 1, width: 300, maxWidth: 300 }}
              />
              <input
                onChange={handlePhoneNumberChange}
                onWheel={e => {
                  e.currentTarget.blur();
                }}
                className="number-no-arrows dialog-content__inputs input-width"
                id="demo-helper-text-misaligned-no-helper"
                type="number"
                min="0"
              />
            </div>
            {!verify.forgotPassword && (
              <>
                <label className="dialog-content__labels">Password</label>
                <div className="dialog-content__birthdate">
                  <img className="dialog-content__number" src={secondLogo} alt="SecondLogo" />
                  <input
                    type={showPassword ? 'text' : 'password'}
                    className="number-no-arrows dialog-content__inputs"
                    placeholder="Password"
                    id="demo-helper-text-misaligned-no-helper"
                    onChange={handlePasswordChange}
                  />
                  <i
                    className={`toggle-password ${showPassword ? 'far fa-eye-slash' : 'far fa-eye'}`}
                    id="togglePassword"
                    onClick={togglePasswordShow}
                  />
                </div>
                <div style={{ float: 'right', marginRight: '40px' }}>
                  <button className="forgot-password" onClick={handleForgotPassword}>
                    Forgot password?
                  </button>
                </div>
              </>
            )}
          </div>
        </DialogContent>
        <DialogActions className="dialog-content__button">
          <button className="apply" onClick={verify.forgotPassword ? handleForgotSubmit : handleSubmit} autoFocus>
            Submit
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default LogReg;
