import './App.css';
import React from 'react';
import { RecoilRoot } from 'recoil';
import NavRoutes from './components/NavRoutes';

function App() {
  return (
    <RecoilRoot>
      <div className="App">
        <NavRoutes />
      </div>
    </RecoilRoot>
  );
}

export default App;
