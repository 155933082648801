import React, { useEffect, useState } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import hatBlueLogo from '../../assests/images/hatBlueLogo.svg';
import ThumsUpLogo from '../../assests/images/ThumsUpLogo.svg';
import TelegramContactLogo from '../../assests/images/TelegramContactLogo.svg';
import calendarBlueLogo from '../../assests/images/calendarBlueLogo.svg';
import clockBlueLogo from '../../assests/images/clockBlueLogo.svg';
import dollarBlueLogo from '../../assests/images/dollarBlueLogo.svg';
import locationBlueLogo from '../../assests/images/locationBlueLogo.svg';
import profileBlueLogo from '../../assests/images/profileBlueLogo.svg';
import sevenBlueLogo from '../../assests/images/sevenBlueLogo.svg';
import './ApplyModal.css';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { useRecoilState } from 'recoil';
import axios from '../../utils/axios';
import { assignmentState, shouldReloadState } from '../../recoil/assignmentAtoms';
import { useForm } from 'react-hook-form';
import useLogin from '../../store/AuthStore';

const ApplyModal = ({ open = false, onClose = () => {}, tuitionData = {} }) => {
  const { auth } = useLogin();
  const [assignment, setAssignment] = useRecoilState(assignmentState);
  const [rate, setRate] = useState('');
  const [reload, setReload] = useRecoilState(shouldReloadState);
  const [thankYouOpen, setThankYouOpen] = useState(false);

  useEffect(() => {
    let params = {
      assignment_id: tuitionData.assignment_id,
    };
    if (open) {
      axios.get('assignments/apply', { params }).then(data => {
        setAssignment(prevState => ({
          prevState,
          all_groups: data.data.all_groups,
          all_hourly_rates: data.data.all_hourly_rates,
          day_and_time: data.data.day_and_time,
          details_of_experience: data.data.details_of_experience,
          group: data.data.group,
          highest_qualification: data.data.highest_qualification,
          years_of_experience: data.data.years_of_experience,
        }));
      });
    }
  }, [open]);

  const handleRateChange = ({ target: { value } }) => {
    setRate(value);
    setValue('rate', '');
  };

  const handleGroupChange = ({ target: { value } }) => {
    setAssignment(prevState => ({
      ...prevState,
      group: value,
    }));
  };
  const resetRateSelect = () => {
    setRate('');
  };

  const submitAssignmentApplication = data => {
    let sendingRate;
    if (data.rate) {
      sendingRate = `$${data.rate}/hr`;
    } else {
      sendingRate = rate;
    }
    if (!data.day_and_time || (!data.rate && !rate)) {
      setError('day_and_time', {
        type: 'manual',
        message: 'This field is required',
      });
      if (!data.rate && !rate) {
        setError('rate', {
          type: 'manual',
          message: 'You must either select or write the rate per hour',
        });
      }
      return;
    }

    const log = {
      assignment_id: tuitionData.assignment_id,
      all_groups: assignment.all_groups,
      all_hourly_rates: assignment.all_hourly_rates,
      day_and_time: data.day_and_time,
      details_of_experience: data.details_of_experience,
      group: assignment.group,
      highest_qualification: data.highest_qualification,
      years_of_experience: data.years_of_experience,
      hourly_rate: sendingRate,
    };
    axios.post('assignments/apply', log).then(data => {
      setReload(prev => ({
        ...prev,
        shouldReload: !reload.shouldReload,
      }));
    });
    onClose();
    openThankYou();
  };

  const Rates = () => {
    if (assignment.all_groups) {
      return (
        <FormControl sx={{ m: 1, minWidth: 120, width: 180 }} size="small">
          <Select
            clasName="select"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={rate}
            onChange={handleRateChange}
          >
            {assignment.all_hourly_rates.map(rate => (
              <MenuItem value={rate}>{rate}</MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    }
  };
  const Groups = () => {
    if (assignment.all_groups) {
      return (
        <FormControl sx={{ m: 1, minWidth: 120, width: 180 }}>
          <Select
            clasName="select apply-select-width"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={assignment.group}
            onChange={handleGroupChange}
            style={{ height: 37 }}
          >
            {assignment.all_groups.map(group => (
              <MenuItem value={group}>{group}</MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    }
  };

  const openThankYou = () => {
    setThankYouOpen(true);
  };
  const onCloseThankYou = () => {
    setThankYouOpen(false);
  };
  const {
    register,
    setValue,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    setValue('highest_qualification', assignment.highest_qualification);
    setValue('years_of_experience', assignment.years_of_experience);
    setValue('details_of_experience', assignment.details_of_experience);
  }, [assignment.highest_qualification, assignment.years_of_experience, assignment.details_of_experience]);

  return (
    <>
      <Dialog
        disableRestoreFocus
        disableScrollLock
        className={`dialog ${auth ? 'not-auth' : 'auth'}`}
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: '630px',
              height: '100%',
              maxHeight: '80vh',
            },
          },
        }}
      >
        <IconButton
          edge="start"
          color="inherit"
          onClick={onClose}
          aria-label="close"
          sx={{
            position: 'absolute',
            right: 8,
            color: theme => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle id="alert-dialog-title" className="dialog-nav"></DialogTitle>
        <div>
          <div className="content-wrapper">
            <div className="dialog__row">
              <img src={profileBlueLogo} alt="#" />
              <p>{tuitionData.preference}</p>
            </div>
            <div className="dialog__row">
              <img src={hatBlueLogo} alt="#" />
              <p>{tuitionData.subject}</p>
            </div>
            <div className="dialog__row">
              <img src={locationBlueLogo} alt="#" />
              <p>{tuitionData.location}</p>
            </div>
            <div className="dialog__row">
              <img src={dollarBlueLogo} alt="#" />
              <p>{tuitionData.rate}</p>
            </div>
            <div className="dialog__row">
              <img src={calendarBlueLogo} alt="#" />
              <p>{tuitionData.time}</p>
            </div>
            <div className="dialog__row">
              <img src={sevenBlueLogo} alt="#" />
              <p>{tuitionData.frequency}</p>
            </div>
            <div className="dialog__row">
              <img src={clockBlueLogo} alt="#" />
              <p>{tuitionData.date}</p>
            </div>
          </div>
          <div className="blue-bg">
            <div className="content-wrapper">
              <form onSubmit={handleSubmit(submitAssignmentApplication)}>
                <div>
                  <label className="label-underline">Propose your available day and time</label>
                  <input className="info__input info__input-mb info-padding-left" {...register('day_and_time')} />
                  <span style={{ color: 'red' }}>{errors.day_and_time && errors.day_and_time.message}</span>
                </div>
                <div>
                  <label className="label-underline">Propose your rate per hour</label>
                  <div className="select__row">
                    <Rates />

                    <span className="select__text">or</span>
                    <input
                      className="info__input info__input--width rate-input"
                      placeholder="$00"
                      {...register('rate', {
                        pattern: { value: /^[0-9]+$/, message: 'This can only be a positive number' },
                      })}
                      onChange={resetRateSelect}
                    />
                    <span style={{ color: 'red' }}>{errors.rate && errors.rate.message}</span>
                  </div>
                </div>
                <div className="info">
                  <div className="label-underline">Details:</div>
                  <div className="info__row">
                    <label className="info__label">Group:</label>
                    <Groups />
                  </div>
                  <div className="info__row">
                    <label className="info__label">Highest qualification:</label>
                    <input
                      className="info__input info-padding-left"
                      placeholder="Cambridge University 2005 BF-15 group"
                      {...register('highest_qualification', {
                        maxLength: {
                          required: 'This field is required',
                          value: assignment.highestQualificationLimit,
                          message: `You can only have ${assignment.highestQualificationLimit} characters`,
                        },
                      })}
                    />
                    <span style={{ color: 'red' }}>
                      {errors.highest_qualification && errors.highest_qualification.message}
                    </span>
                  </div>
                  <div className="info__row">
                    <label className="info__label">Years of experience:</label>
                    <input
                      className="info__input info-padding-left"
                      placeholder="Years of experience"
                      {...register('years_of_experience', {
                        required: 'This field is required',
                        pattern: { value: /^[0-9]+$/, message: 'This can only be a positive number' },
                      })}
                    />
                    <span style={{ color: 'red' }}>
                      {errors.years_of_experience && errors.years_of_experience.message}
                    </span>
                  </div>
                </div>
                <label className="info__label">Detail of experience:</label>
                <textarea
                  className="dialog__textarea info-padding-left"
                  placeholder="Tell something about your experience"
                  {...register('details_of_experience', {
                    maxLength: {
                      value: assignment.experienceLimit,
                      message: `You can only have ${assignment.experienceLimit} characters`,
                    },
                  })}
                />
                <span style={{ color: 'red' }}>{errors.experienceLimit && errors.experienceLimit.message}</span>
                <DialogActions className="dialog-content__button">
                  <button className="apply" type="submit">
                    Submit
                  </button>
                </DialogActions>
                <p className="consent">
                  By clicking "submit", it means that you consent to sharing all your personal details, certificates
                  and/or photo with the job poster. You also give consent for them to contact you and share your
                  information with any relevant parties (eg: parent, student or tuition center).
                </p>
              </form>
            </div>
          </div>
        </div>
      </Dialog>
      <ThankYouModal thankYouOpen={thankYouOpen} onCloseThankYou={onCloseThankYou} />
    </>
  );
};

const ThankYouModal = ({ thankYouOpen, onCloseThankYou }) => {
  const [telegramLinks, setTelgeramsLinks] = useState();
  useEffect(() => {
    axios.get('assignments/success').then(data => {
      setTelgeramsLinks(data.data.telegram_channels_list);
    });
  }, []);

  return (
    <>
      <Dialog
        open={thankYouOpen}
        onClose={onCloseThankYou}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: '630px',
              height: '100%',
              maxHeight: '80vh',
            },
          },
        }}
      >
        <IconButton
          edge="start"
          color="inherit"
          onClick={onCloseThankYou}
          aria-label="close"
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle id="alert-dialog-title" className="dialog-nav">
          <img className="dialog-nav__icon" src={ThumsUpLogo} alt="loginLogo" />
          Thank you!
        </DialogTitle>
        <div className="blue-bg thank-bg">
          <h2 className="thank-heading">Thank you for applying!</h2>
          <h3 className="thank-text">We will contact you if are selected</h3>
          <div className="telegram-links">
            {telegramLinks?.map(link => {
              return (
                <div key={link} className="telegram-link">
                  <img src={TelegramContactLogo} alt="TelegramContactLogo" />
                  <a className="telegram-link-text" href={`https://telegram.me/${link.replace('@', '')}`}>
                    {link}
                  </a>
                </div>
              );
            })}
          </div>
          <DialogActions className="dialog-content__button">
            <button className="apply" onClick={onCloseThankYou}>
              ok
            </button>
          </DialogActions>
        </div>
      </Dialog>
    </>
  );
};

export default ApplyModal;
