import React from 'react';
import './Footer.css';
import telegramLogo from '../../assests/images/TelegramLogo.svg';

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer__container">
        <div className="footer__links">
          <a className="links footer__telegram" href="https://t.me/TuitionJob">
            Tuition job telegram Channel
            <img className="tg-logo" src={telegramLogo} alt="telegram_logo" />
          </a>
          <a className="links footer__telegram" href="https://t.me/SgPartTimeAgency">
            Part Time Job Telegram Channel
            <img className="tg-logo" src={telegramLogo} alt="telegram_logo" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
