import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import React, { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { categoryState } from '../../recoil/mainAtoms';

const Categories = () => {
  const [categories, setCategories] = useRecoilState(categoryState);

  const menuItems = categories.categories
    .filter(x => categories.chosenCategories.includes(x.name))
    .map(({ subjects }) => subjects)
    .flat()
    .filter((item, index, self) => index === self.findIndex(i => i.name === item.name));

  useEffect(() => {
    setCategories(prevState => ({
      ...prevState,
      chosenSubjects: [],
    }));
  }, [categories.chosenCategories]);

  const handleCategoryChange = e => {
    setCategories(prevState => ({
      ...prevState,
      chosenCategories: e.target.value,
      chosenSubjects: [],
    }));
  };

  const handleSubjectChange = e => {
    setCategories(prevState => ({
      ...prevState,
      chosenSubjects: e.target.value,
    }));
  };

  return (
    <div>
      <div className="main-inputs__selects">
        <FormControl variant="standard" sx={{ m: 1, width: 300, maxWidth: 300 }}>
          <InputLabel id="demo-multiple-name-label">Category</InputLabel>
          <Select
            labelId="demo-multiple-name-label"
            id="demo-multiple-name"
            multiple
            value={categories.chosenCategories}
            onChange={handleCategoryChange}
          >
            {categories.categories.map(({ name }) => (
              <MenuItem key={name} value={name}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="standard" sx={{ m: 1, minWidth: 300, maxWidth: 300 }}>
          <InputLabel id="demo-simple-select-standard-label">Subject</InputLabel>
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            value={categories.chosenSubjects}
            multiple
            onChange={handleSubjectChange}
            disabled={menuItems.length < 1}
          >
            {menuItems.map(({ name }, idx) => (
              <MenuItem key={`${name}-${idx}`} value={name}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </div>
  );
};

export default Categories;
