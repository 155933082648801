import React, { useCallback, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import PersonLogo from '../../assests/images/placeholder-dropzone.svg';
import photoIcon from '../../assests/images/photo.svg';

import './DropZone.css';
import { useRecoilState } from 'recoil';
import { formState } from '../../recoil/formAtom';
const jpegBase64Header = 'data:image/jpeg;base64,';
const jpgBase64Header = 'data:image/jpg;base64,';
const pngBase64Header = 'data:image/png;base64,';

const DropZone = () => {
  const [form, setForm] = useRecoilState(formState);
  const baseStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '378px',
    width: '378px',
    padding: '0px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#EFEFEF',
    color: '#bdbdbd',
    transition: 'border .3s ease-in-out',
  };

  const activeStyle = {
    borderColor: '#2196f3',
  };

  const acceptStyle = {
    borderColor: '#00e676',
  };

  const rejectStyle = {
    borderColor: '#ff1744',
  };

  const onDrop = useCallback(acceptedFiles => {
    let reader = new FileReader();
    reader.readAsDataURL(acceptedFiles[0]);
    reader.onload = function (e) {
      let imgB64 = reader.result;

      if (imgB64.includes(jpegBase64Header)) {
        imgB64 = imgB64.replace(jpegBase64Header, '');
      }
      if (imgB64.includes(pngBase64Header)) {
        imgB64 = imgB64.replace(pngBase64Header, '');
      }
      if (imgB64.includes(jpgBase64Header)) {
        imgB64 = imgB64.replace(jpgBase64Header, '');
      }

      setForm(prevState => ({
        ...prevState,
        profilePicture: imgB64,
        chosenProfilePicture: imgB64,
      }));
    };
  }, []);

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    onDrop,
    accept: 'image/jpeg,image/jpg, image/png',
  });
  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept],
  );

  return (
    <div className="dropzone">
      <div className="dropzone__relative" {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <img className="dropzone__placeholder" src={PersonLogo} alt="PersonLogo" />
        <img className="dropzone__icon" alt="#" src={photoIcon} />
        <div className="dropzone__text">Drag and drop your images here.</div>
        <div className="dropzone__text">Or click to choose manually.</div>
      </div>
    </div>
  );
};

export default DropZone;
