import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import './DeleteModal.css';
import axios from '../../utils/axios';

const DeleteModal = ({ open, handleClose }) => {
  const [deleted, setDeleted] = useState(false);

  const close = () => {
    setDeleted(false);
    handleClose();
  };

  const deleteProfile = () => {
    axios.delete('profile/delete').then();
    setDeleted(true);
  };

  return (
    <div className="">
      <Dialog
        className="dialog dialog-padding-top"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: '630px',
              height: '100%',
              maxHeight: '480px',
            },
          },
        }}
      >
        <DialogTitle id="alert-dialog-title" className="dialog-nav">
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: theme => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        {!deleted ? (
          <>
            {' '}
            <DialogContent className="delete-message dialog-flex">
              <div className="first-line">
                <p className="delete-message">Are you sure you want to delete your profile?</p>
              </div>
              <div className="second-line">
                <p className="delete-message">
                  By deleting your profile, companies will no longer be able to contact you for the jobs that you
                  applied
                </p>
              </div>
            </DialogContent>
            <DialogActions className="dialog-content__button">
              <Button className="apply delete-button" onClick={deleteProfile} autoFocus>
                Delete
              </Button>
              <Button className="apply" onClick={handleClose} autoFocus>
                No
              </Button>
            </DialogActions>
          </>
        ) : (
          <>
            <DialogContent className="dialog_message dialog-flex">
              <p className="delete-message first-line">Thanks you for keeping your profile!</p>
              <p className="delete-message">Your profile has been successfully deleted</p>
            </DialogContent>
            <DialogActions className="dialog-content__button">
              <Button className="apply" onClick={close} autoFocus>
                Ok
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </div>
  );
};

export default DeleteModal;
